(function() {

$(window).load(function() { 

	$('.home .slider').slick({
	  infinite: true,
	  slidesToShow: 1,
	  arrows: false,
	  accessibility: true,
	  dots: true
	});

	$('.single .slider').slick({
	  infinite: true,
	  slidesToShow: 1,
	  arrows: true,
	  accessibility: true,
	  centerMode: true,
	  focusOnSelect: true,
	  dots: true,
	  centerPadding: '125px',
	  prevArrow: '<button type="button" class="slick-prev"><i class="icon icon--left-dir"></i></button>',
	  nextArrow: '<button type="button" class="slick-next"><i class="icon icon--right-dir"></i></button>',
	  responsive: [
	    {
	      breakpoint: 768,
	      centerPadding: 0,
	      settings: {
			dots: false,
			centerMode: false
	      }
	    }	
	  ]  
	});

});

$(window).on('load resize', function() { 

	$('.slick-dots').css( 'left', function() {
		var pos = ( $(this).parent().width() - $(this).width() ) / 2;
		return pos;

	});

});

$('.btn--navigation').click(function() {

	$('.nav-collapse').fadeToggle();

});

// $(window).load(function(){

// 	$('.acf-map').each(function(){

// 	  var $map = $(this),
// 	        lat = $map.data('lat'),
// 	        lng = $map.data('lng'),
// 	        zoom = $map.data('zoom'),
// 	        latlng = new google.maps.LatLng(lat, lng),
// 	        map = new google.maps.Map($map[0], {
// 	          zoom   : zoom,
// 	          center : latlng,
// 		      styles: [{"featureType":"landscape","stylers":[{"saturation":-100},{"lightness":65},{"visibility":"on"}]},{"featureType":"poi","stylers":[{"saturation":-100},{"lightness":51},{"visibility":"simplified"}]},{"featureType":"road.highway","stylers":[{"saturation":-100},{"visibility":"simplified"}]},{"featureType":"road.arterial","stylers":[{"saturation":-100},{"lightness":30},{"visibility":"on"}]},{"featureType":"road.local","stylers":[{"saturation":-100},{"lightness":40},{"visibility":"on"}]},{"featureType":"transit","stylers":[{"saturation":-100},{"visibility":"simplified"}]},{"featureType":"administrative.province","stylers":[{"visibility":"off"}]},{"featureType":"water","elementType":"labels","stylers":[{"visibility":"on"},{"lightness":-25},{"saturation":-100}]},{"featureType":"water","elementType":"geometry","stylers":[{"hue":"#ffff00"},{"lightness":-25},{"saturation":-97}]}]
// 	        }),
// 	        marker = new google.maps.Marker({
// 	          position  : latlng,
// 	          map       : map
// 	        });

// 		});

// });

})($);


/**
 * reCAPTCHA
 */

grecaptcha.ready(function() {
	var g_response = $('#g-recaptcha-response');
	if ( g_response.length )
	{
		grecaptcha.execute('6LeDgtQaAAAAAHwTYdauUZD0csefR9vK06olAB1D', {action:'validate_captcha'})
    	.then(function(token) {
        	g_response.val(token);
    	});
	}
});


/**
 * Submit form
 */

$("#enquiry-form").submit(function(e) {
  	e.preventDefault();
  	var form_data = $(this).serialize();
	$.ajax({ 
	  type: 'post',
	  url: epic_tomato.ajax_url, 
	  data: {
	    action: 'submit_form',
	    data: form_data,
	  },
	  beforeSend: function() { 
	    $('.btn--enquire').prop('disabled', true);
	    $('.btn--enquire').html('Sending...');
	  },
	  success: function(response) {
	    location.href = 'https://epictomato.com/thank-you-for-your-enquiry/';
	  } 
	});
});

